/**
 * Store cookies with commission vendor data
 * @param request
 * @returns
 */
function manageCommissionCookies(sfData) {
  var site_refer = sfData.site_refer;
  // do not store cookies if site_refer parameter does not exist or empty
  if (!validateSiteRefer(site_refer)) {
    return;
  }
  var commissionCookieNames = ['site_refer', 'sf_storeid', 'sf_associd'];
  var age = 14 * 24 * 60 * 60 * 1000;

  var cookieMap = {
    site_refer: 'site_refer',
    sf_storeid: 'ref_storeid',
    sf_associd: 'ref_associd'
  };

  commissionCookieNames.forEach(function (name) {
    var value = sfData[name] || '" "';
    var cookieID = cookieMap[name];

    if (cookieID === 'site_refer') {
      // Update string value to match value set on BE side
      value = '"' + value + '"';
    }

    var expires = new Date();
    expires.setTime(expires.getTime() + age);
    document.cookie = cookieID + '=' + value + ';path=/;Secure;SameSite=None;expires=' + expires.toUTCString();
  });
}

/**
 * Delete cookies with commission vendor data
 * @returns {void}
 */
function deleteCommissionCookies() {
  var commissionCookieNames = ['site_refer', 'ref_storeid', 'ref_associd'];
  var value = '" "';
  var expires = new Date();
  expires.setTime(expires.getTime() - 1 * 24 * 60 * 60 * 1000);

  commissionCookieNames.forEach(function (cookieName) {
    document.cookie = cookieName + '=' + value + ';path=/;Secure;SameSite=None;expires=' + expires.toUTCString();
  });
}

function getUrlParameter(name, queryString) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(queryString);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function getCookie(name) {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');
  if (parts.length >= 2) return parts.pop().split(';').shift();
}

/**
 * Attribution logic for cookies
 * @param value
 * @returns
 */
function validateSiteRefer(value) {
  if (!value) {
    return false;
  }

  // EML and TR referrers have lower priority comparing to CNCT, COMX, TULIP and SALESFLOOR ref
  if (value.includes('EML') || value.includes('TR')) {
    var getSite_Refer = (getCookie('site_refer') || '').toUpperCase();
    if (getSite_Refer && (getSite_Refer.includes('CNCT') 
      || getSite_Refer.includes('COMX') 
      || getSite_Refer.includes('TULIP') 
      || getSite_Refer.includes('SALESFLOOR'))) {
      return false;
    }
  }

  var site_ref_search = 'SEM';
  var site_ref_email = 'EML';
  var site_ref_email_SF = 'EMLHB_SF';
  var emlRegexp = new RegExp('^(' + site_ref_email + '|' + site_ref_search + ')');
  if (emlRegexp.test(value)) {
    if (value !== site_ref_email_SF) {
      return false;
    }
  }
  return true;
}
function prepareSFdate(data) {
  var sfData = {};
  var queryString = window.location.search;
  var site_refer = getUrlParameter('site_refer', queryString);

  if (site_refer) {
    sfData.site_refer = site_refer;
  } else if (data.site_refer) {
    sfData.site_refer = data.site_refer;
  } else {
    sfData.site_refer = 'salesfloor';
  }

  if (site_refer.includes('EML') || site_refer.includes('TR')) {
    sfData.site_refer = 'salesfloor';
  }

  if (sfData.site_refer == 'salesfloor') {
    if (data.store_id) sfData.sf_storeid = data.store_id;
    if (data.employee_id) sfData.sf_associd = data.employee_id;
  } else {
    sfData.sf_associd = getUrlParameter('sf_associd', queryString);
    sfData.sf_storeid = getUrlParameter('sf_storeid', queryString);
  }

  return sfData;
}

module.exports = function () {
  $(window).on('load', function () {
    const queryString = window.location.search;
    const isCheckoutPage = window.pageData.page?.type === 'shopping bag' || window.pageData.page?.checkoutStep?.length;
    const isSiteReferrer = queryString.includes('site_refer');

    if (isSiteReferrer || isCheckoutPage) {
      setTimeout(() => {
        try {
          sf_widget.utils.getTrackingEmployeeObjectAsPromise().then(function (data) {
            var siteReferCookie = getCookie('site_refer');
            if (data) {
              const sfData = prepareSFdate(data);
              manageCommissionCookies(sfData);
            } else if (isCheckoutPage && !data && siteReferCookie && siteReferCookie.indexOf('salesfloor') > -1) {
              deleteCommissionCookies();
            }
          });
        } catch (e) {
          console.log('There was an error with the SF_widget : ' + e.message);
        }

        try {
          sf_widget.utils.isWithinGeo().then(function (data) {
            if (data === true) {
              $('.stylist-chat-btn').prop('disabled', false);
              sessionStorage.setItem('sf_within_geo', true);
            } else {
              $('.stylist-chat-btn').prop('disabled', true);
            }
          });
        } catch (e) {
          console.log('There was an error with the SF_widget utils: ' + e.message);
        }
      }, 2500);
    }

    if (isSiteReferrer) {
      const site_refer = getUrlParameter('site_refer', queryString);

      if (site_refer !== '') {
        manageCommissionCookies({
          site_refer: site_refer,
          sf_associd: getUrlParameter('sf_associd', queryString),
          sf_storeid: getUrlParameter('sf_storeid', queryString)
        });
      }
    }
  });
  
};
